<!-- 新增文件 -->

<template>
  <w-dialog ref="dialogRef" class="vip-dialog" :title="ruleForm.smr_id ? '编辑文件' : '新增文件'" width="50%" btnWidth="140px"
    top="20vh" :confirmText="ruleForm.smr_id ? '确认编辑' : '确认新增'" @wConfirm="handleSure" :modalClick="false">
    <el-form class="add-form" ref="formRef" :model="ruleForm" :rules="rules" labelPosition="top">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="文件名称" prop="smr_name">
            <el-input v-model.trim="ruleForm.smr_name" clearable placeholder="请输入文件名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属分类" prop="smr_smcid">
            <el-select v-model="ruleForm.smr_smcid" clearable placeholder="请选择分类">
              <el-option :label="item.smc_name" :value="item.smc_id" v-for="item in classificaOptions"
                :key="item.smc_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item class="upload" label="" prop="cover_files">
            <div>
              <span class="required">*</span>
              文件图片
            </div>
            <img-upload :needImg="false" :limit="1" uploadTitle="文件图片" :fileList="imageLists" @uploadFile="uploadImage"
              @deleteFile="deleteImage" suggestText="">
            </img-upload>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item class="upload" label="" prop="smr_path">
            <div>
              <span class="required">*</span>
              文件
            </div>
            <FilesUpload :fileLists="fileLists" @uploadFile="uploadFile" @deleteFile="deleteFile" :accept="accept">
            </FilesUpload>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item prop="smr_remark" label="内容介绍">
            <el-input v-model.trim="ruleForm.smr_remark" type="textarea" rows="3" placeholder="请输入内容介绍">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </w-dialog>
</template>

<script>
  import { ref, reactive, toRefs, computed } from "vue";
  import { ElMessage } from "element-plus";
  import imgUpload from "@/components/img-upload/img-upload.vue";
  import { ScenicApi } from "@/plugins/api.js";
  import { useStore } from "vuex";
  import FilesUpload from "./filesUpload.vue";

  export default {
    name: "AddFilePage",
    components: { imgUpload, FilesUpload },
    emits: ["submit"],
    setup(props, { emit }) {
      const store = useStore();
      const qiniuData = computed(() => store.state.Config.qiniuData);  // 七牛云上传参数
      const dialogRef = ref(null);  // 对话框对象
      const formRef = ref(null);  // 表单对象
      const imageLists = ref([]);  // 图片列表
      const fileLists = ref([]);  // 文件列表
      const accept = ref(".wav, .mp3, .ogg, .acc, .webm, .amr");
      // 页面数据
      const state = reactive({
        classificaOptions: [],  // 分类选项
        // 新增、编辑商品填写内容
        ruleForm: {
          smr_name: "",  // 文件名称
          smr_smcid: "",  // 所属分类
          cover_files: "",  // 图片
          smr_remark: "",  // 备注
          smr_path: [],  // 音频文件路径
          file_type: 1,  // 文件类型
        },
      });
      /**
       * 
       * 显示对话框
       * type: 1.新增  2.编辑
       * 
       * */
      function openDialog(type, row) {
        store.dispatch("getQiniuData");
        dialogRef.value.isLoading = false;
        imageLists.value = [];
        fileLists.value = [];
        if (type == '1') {
          state.ruleForm = {
            smr_name: "",  // 文件名称
            smr_smcid: "",  // 所属分类
            cover_files: "",  // 图片
            smr_remark: "",  // 备注
            smr_path: [],
            file_type: 1,
          };
        } else {
          imageLists.value.push(row.coverFile);
          fileLists.value.push({ key: row.smr_path, name: row.file_name });
          state.ruleForm.smr_name = row.smr_name;
          state.ruleForm.smr_smcid = row.smc_id;
          state.ruleForm.smr_remark = row.smr_remark;
          state.ruleForm.smr_id = row.smr_id;
        }
        getClassesLists();
        dialogRef.value.show();
      }
      /**
       * 
       * 获取分类列表
       * 
       * */
      const getClassesLists = () => {
        ScenicApi.getClasses().then((res) => {
          if (res.Code === 200) {
            state.classificaOptions = res.Data ? res.Data : [];
          } else {
            let msg = res.Message ? res.Message : "数据获取失败！";
            ElMessage.error(msg);
          }
        })
          .catch(() => {
            state.emptyText = "数据获取失败！";
          });
      }
      /**
       * 
       * 文件上传成功
       * 
       * */
      const uploadFile = (data) => {
        fileLists.value.push({ ...data });
      }
      /**
       * 
       * 文件删除
       * 
       * */
      const deleteFile = (data) => {
        let index1 = fileLists.value.findIndex((item) => item.key === data.key);
        fileLists.value.splice(index1, 1);
      }
      /**
       * 
       * 关闭对话框
       * 
       * */
      function closeDialog() {
        dialogRef.value.close();
      }
      // 验证内容不为空
      const rules = reactive({
        smr_name: [{ required: true, message: "请输入文件名称", trigger: "blur" }],
        smr_smcid: [{ required: true, message: "请选择所属分类", trigger: "chaneg" }],
      });
      /**
       * 
       * 图片上传
       * 
       * */
      const uploadImage = (data) => {
        imageLists.value.push({ file_url: data.standard_url, key: data.key });
      };
      /**
       * 
       * 图片删除
       * 
       * */
      const deleteImage = (data) => {
        let index1 = imageLists.value.findIndex((item) => item.key === data.key);
        imageLists.value.splice(index1, 1);
      };
      /**
       * 
       * 确认按钮
       * 
       * */
      function handleSure() {
        formRef.value.validate((valid) => {
          if (valid) {
            if (imageLists.value.length == 0) {
              ElMessage.error("请上传图片！");
              return false;
            }
            if (fileLists.value.length == 0) {
              ElMessage.error("请上传文件！");
              return false;
            }
            state.ruleForm.cover_files = !imageLists.value[0].key ? imageLists.value[0].sf_path : imageLists.value[0].key;
            state.ruleForm.smr_path = fileLists.value;
            const data = JSON.parse(JSON.stringify(state.ruleForm));
            let url = "";
            if (data.smr_id) {
              // 编辑
              url = "editSoundFile";
              data["smr_id"] = data.smr_id;
            } else {
              // 新增
              url = "addSoundFile";
            }
            dialogRef.value.isLoading = true;
            ScenicApi[url](data).then((res) => {
              if (res.Code === 200) {
                ElMessage.success(`${data.smr_id ? "修改" : "新增"}成功！`);
                closeDialog();
                emit("onConfirm");
              } else {
                let msg = res.Message ? res.Message : `${data.smr_id ? "修改" : "新增"}失败！`;
                ElMessage.error(msg);
              }
              dialogRef.value.isLoading = false;
            });
            // emit();
          }
        });
      }

      return {
        ...toRefs(state),
        dialogRef,
        openDialog,
        closeDialog,
        handleSure,
        formRef,
        imageLists,
        rules,
        uploadImage,
        deleteImage,
        store,
        qiniuData,
        fileLists,
        uploadFile,
        deleteFile,
        getClassesLists,
        accept,
      };
    },
  };
</script>

<style lang="scss">
  .barcode-content {
    margin-top: 20px;
    flex-flow: wrap;
  }

  .barcode-text {
    margin-right: 10px;
  }

  .circle-num {
    padding: 10px 16px;
    background: var(--menu-bg-color);
    margin-top: 10px;
    border-radius: 10px;
  }

  .circle-close {
    cursor: pointer;
    position: absolute;
    right: -4px;
    top: 4px;
  }

  .empty-text {
    text-align: center;
    margin: 40px 0;
  }

  .content-title {
    margin-bottom: 5px;
  }

  //上传样式
  .upload-btn {
    border: 1px solid var(--search-uncheck-bg-color);
    background-color: var(--search-uncheck-bg-color);
    width: 50px;
    height: 50px;
    position: relative;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 20px;
      margin-left: -10px;
      border-top: 1px solid var(--text-third-color);
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      height: 20px;
      margin-top: -10px;
      border-left: 1px solid var(--text-third-color);
    }
  }

  .required {
    color: var(--el-color-danger);
  }
</style>