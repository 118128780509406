<!-- 文件管理 -->

<template>
  <div class="main-cnt">
    <div class="title">文件列表</div>

    <audio :src="mp3url" ref="audioMedia" controls v-show="false"></audio>

    <!-- 列表数据 -->
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 325px)" :ischeck="false" :filters="filters"
        :ispaging="true" :apiName="ScenicApi.getSoundFileLists" :columns="tableColumns" @edit="onEditBtn"
        @classesChange="classesChange" @statusChange="statusChange" @audition="onAuditionBtn">
        <template #operate>
          <el-button type="primary" round @click="onEditClassification"
            v-if="isSelesePro && authData.indexOf('n_5A4933F3F264B61A07GEFC3A756E') != -1">
            编辑分类
          </el-button>
          <el-button type="primary" round @click="onAddBtn(1)"
            v-if="authData.indexOf('n_5A4933F3F76A921A07GEFC3A756E') != -1">
            新增分类
          </el-button>
          <el-button type="primary" round @click="onAddBtn(2)"
            v-if="authData.indexOf('n_5A4933FHFKI3921A07GEFC3A756E') != -1">
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增文件
          </el-button>
        </template>
      </common-table>
    </div>

    <!-- 新增分类 -->
    <AddClassifiPage ref="addClassifiRef" @onConfirm="onConfirmClasses"></AddClassifiPage>

    <!-- 新增文件 -->
    <AddFilePage ref="addFileRef" @onConfirm="onConfirmSoundFile"></AddFilePage>
  </div>
</template>

<script>
  import { ref, reactive, toRefs, computed, onMounted, watch, } from "vue";
  import { ScenicApi } from "@/plugins/api.js";
  import { useStore } from "vuex";
  import AddClassifiPage from "../components/AddClassifiPage.vue";
  import AddFilePage from "../components/AddFilePage.vue";
  import { ElMessage } from "element-plus";

  export default {
    name: "FileManagement",
    components: {
      AddClassifiPage,
      AddFilePage,
    },
    // emits: ["submit"],
    // props, { emit }
    setup() {
      const store = useStore();
      const authData = ref([]);
      const menuTokens = computed(() => store.state.menuToken.menuTokens);  // 权限
      watch(
        () => menuTokens.value,
        (data) => {
          if (data.length) {
            authData.value = data;
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
      const tableRef = ref(null);  // 表格组件对象
      const addClassifiRef = ref(null);  // 新增分类对话框对象
      const addFileRef = ref(null);  // 新增文件对话框对象
      const isSelesePro = ref(false);  // 是否选择项目
      // 页面数据
      const state = reactive({
        currentClasses: "",  // 当前选中分类
      });
      // 表格配置
      const tableColumns = ref([
        {
          prop: "smr_id",
          label: "文件ID",
        },
        {
          prop: "smr_name",
          label: "文件名称",
          color: "--text-third-color",
        },
        {
          prop: "smc_name",
          label: "分类",
          color: "--text-third-color",
        },
        {
          prop: "file_url",
          type: "image",
          label: "图片",
          color: "--text-third-color",
        },
        {
          prop: "smr_remark",
          label: "内容介绍",
          minWidth: 200,
          color: "--text-third-color",
        },
        {
          prop: "smr_status",
          type: "switch",
          label: "启用状态",
          token: "n_5A1HUSY5I3F9HFUA27GEFC3A756E",
        },
        {
          prop: "u_name",
          label: "创建人",
          color: "--text-third-color",
        },
        {
          prop: "smr_ctime",
          label: "创建时间",
          minWidth: 120,
          color: "--text-third-color",
        },
        {
          type: "operation",
          prop: "",
          label: "操作",
          minWidth: 200,
          bottons: [
            {
              name: "编辑",
              action: "edit",
              token: "n_5A11223HI3F9HFUA27GEFC3A756E",
            },
            {
              name: "试听",
              action: "audition",
              token: "n_5A11223HFKI3921A07GEFC3A756E",
            },
          ],
        },
      ]);
      // 筛选配置
      const filters = ref([
        {
          filterType: "select",
          name: "smr_smcid",
          value: "",
          placeholder: "请选择分类",
          options: [],
          val: "smc_id",
          lab: "smc_name",
          action: "classesChange",
        },
        {
          filterType: "search",
          name: "keyword",
          value: "",
          placeholder: "请输入输入文件名称搜索",
        },
      ]);
      /**
       * 
       * 获取分类列表
       * 
       * */
      const getClassesLists = () => {
        ScenicApi.getClasses().then((res) => {
          // console.log("分类列表", res);
          if (res.Code === 200) {
            filters.value[0].options = res.Data ? res.Data : [];
            filters.value[0].options.unshift({ smc_id: "", smc_name: "全部" });
          } else {
            let msg = res.Message ? res.Message : "数据获取失败！";
            ElMessage.error(msg);
          }
        })
          .catch(() => {

          });
      }
      /**
       * 
       * 选择项目
       * 
       * */
      const classesChange = (item) => {
        let currentData = {};
        for (let i = 0; i < item.options.length; i++) {
          if (item.value == item.options[i].smc_id) {
            currentData = item.options[i];
          }
        }
        state.currentClasses = currentData;
        isSelesePro.value = !!item.value;
        tableRef.value.tableLoad();
      }
      onMounted(() => {
        tableRef.value.tableLoad();
        getClassesLists();
      });
      /**
       * 
       * 点击新增按钮
       * type：1.新增分类   2.新增文件
       * 
       * */
      const onAddBtn = (type) => {
        if (type == "1") {
          addClassifiRef.value.openDialog("add");
        } else {
          addFileRef.value.openDialog(1);
        }
      }
      /**
       * 
       * 编辑分类按钮
       * 
       * */
      const onEditClassification = () => {
        addClassifiRef.value.openDialog("edit", state.currentClasses);
      }
      /**
       * 
       * 编辑文件按钮
       * 
       * */
      const onEditBtn = (row) => {
        addFileRef.value.openDialog(2, row);
      }
      /**
       * 
       * 新增分类成功重新获取文件列表数据
       * 
       * */
      const onConfirmClasses = () => {
        getClassesLists();  // 重新获取分类列表
        filters.value[0].value = "";  // 清空选中分类
        classesChange(filters.value[0]);  // 选择分类事件
        addClassifiRef.value.closeDialog();  //关闭新增分类对话框
      }
      /**
       * 
       * 新增文件成功重新获取文件列表数据
       * 
       * */
      const onConfirmSoundFile = () => {
        tableRef.value.tableLoad();
      }
      /**
       * 
       * 改变状态事件
       * 
       * */
      function statusChange(row) {
        let data = {
          smr_id: row.smr_id,
          smr_status: row.smr_status == 1 ? 2 : 1,  // 1正常 2禁用
        };
        ScenicApi.updateStatus(data).then((res) => {
          if (res.Code === 200) {
            ElMessage.success("操作成功！");
            tableRef.value.tableLoad();
          } else {
            let msg = res.Message ? res.Message : "操作失败！";
            ElMessage.error(msg);
          }
        });
      }
      /**
      *
      * 试听按钮
      *
      * */
      const audioMedia = ref(null);
      const mp3url = ref(null);
      const onAuditionBtn = (row) => {
        mp3url.value = row.resource_url;
        setTimeout(function () {
          audioMedia.value.play();
        }, 1000);
      }

      return {
        tableRef,
        ...toRefs(state),
        store,
        menuTokens,
        authData,
        tableColumns,
        filters,
        onAddBtn,
        onEditBtn,
        addClassifiRef,
        onConfirmClasses,
        addFileRef,
        onEditClassification,
        getClassesLists,
        isSelesePro,
        classesChange,
        onConfirmSoundFile,
        ScenicApi,
        statusChange,
        onAuditionBtn,
        mp3url,
        audioMedia,
      };
    },
  };
</script>

<style lang="scss">
  .content {
    padding: 15px 20px 20px;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 280px;
  }

  .el-date-editor {
    .el-input__inner {
      background-color: var(--search-bg-color);
      color: var(--theme-color);
      padding-left: 40px;
    }

    .el-input__prefix {
      .el-icon {
        color: var(--theme-color);
        font-size: 18px;
      }
    }

    .el-range-input {
      background-color: transparent;
    }
  }
</style>