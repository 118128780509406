<!-- 新增分类 -->

<template>
  <w-dialog ref="dialogRef" :title="currentClassi.smc_id ? '编辑分类' : '新增分类'" width="40%" top="15vh"
    @wConfirm="handleSure" :confirmText="currentClassi.smc_id ? '确认编辑' : '确认新增'" :modalClick="false">
    <div class="content-title">名称分类</div>
    <el-row :gutter="20">
      <el-col :span="20">
        <el-input v-model.trim="smc_name" clearable placeholder="请输入分类名称"></el-input>
      </el-col>
    </el-row>
  </w-dialog>
</template>

<script>
  import { ref, reactive, } from "vue";
  import { ElMessage } from "element-plus";
  import { ScenicApi } from "@/plugins/api.js";

  export default {
    name: "AddClassifiPage",
    components: {},
    emits: ["submit"],
    setup(props, { emit }) {
      const dialogRef = ref(null);  // 对话框对象
      const smc_name = ref("");
      const currentClassi = ref("");
      /**
       * 
       * 显示对话框
       * 
       * */
      function openDialog(type, item) {
        currentClassi.value = type == 'add' ? {} : item;
        smc_name.value = type == "add" ? "" : item.smc_name;
        dialogRef.value.isLoading = false;
        dialogRef.value.show();
      }
      /**
       * 
       * 关闭对话框
       * 
       * */
      function closeDialog() {
        dialogRef.value.close();
      }
      /**
       * 
       * 确认按钮
       * 
       * */
      function handleSure() {
        // 必填验证
        if (!smc_name.value) {
          ElMessage.error("请输入名称分类!");
          return false;
        }
        let params = { smc_name: smc_name.value }, url = "";
        if (currentClassi.value.smc_id) {
          // 编辑
          url = "editClasses";
          params["smc_id"] = currentClassi.value.smc_id;
        } else {
          // 新增
          url = "addClasses";
        }
        ScenicApi[url](params).then((res) => {
          if (res.Code === 200) {
            emit("onConfirm");
            ElMessage.success("操作成功");
            dialogRef.value.isLoading = true;
          } else {
            let msg = res.Message ? res.Message : "操作失败！";
            ElMessage.error(msg);
          }
        });
      }

      return {
        dialogRef,
        smc_name,
        openDialog,
        closeDialog,
        handleSure,
        currentClassi,
      };
    },
  };
</script>

<style lang="scss">
  .barcode-content {
    margin-top: 20px;
    flex-flow: wrap;
  }

  .barcode-text {
    margin-right: 10px;
  }

  .circle-num {
    padding: 10px 16px;
    background: var(--menu-bg-color);
    margin-top: 10px;
    border-radius: 10px;
  }

  .circle-close {
    cursor: pointer;
    position: absolute;
    right: -4px;
    top: 4px;
  }

  .empty-text {
    text-align: center;
    margin: 40px 0;
  }

  .content-title {
    margin-bottom: 5px;
  }
</style>